import {
  Component,
  ContentChild,
  Input,
  Output,
  TemplateRef,
  EventEmitter,
} from "@angular/core";

import { FolderInfo } from "../folders.component";
import {
  FolderItemListDirective,
  FolderItemGridDirective,
} from "./folders-item.directive";

@Component({
  selector: "[ngx-folders-item], ngx-folders-item",
  templateUrl: "./folders-item.component.html",
  standalone: false,
})
export class FoldersItemComponent<T extends FolderInfo> {
  @Input() item: T;
  @Input() dragable: boolean;
  @Input() selectMode: boolean;
  @Input() mode: "radio" | "submit";
  @Input() dragged: boolean;
  @Input() selected: boolean;
  @Input() grid: boolean;
  @Input() contextOpen: boolean;
  @Input() image: string;

  @Output() clicked = new EventEmitter();
  @Output() drag = new EventEmitter();
  @Output() drop = new EventEmitter();
  @Output() rightClicked = new EventEmitter();

  @ContentChild(FolderItemGridDirective, { read: TemplateRef })
  folderItemGrid: TemplateRef<any>;
  @ContentChild(FolderItemListDirective, { read: TemplateRef })
  folderItemList: TemplateRef<any>;
}
