import { Directive, TemplateRef } from "@angular/core";

@Directive({
  selector: "[folder-item-tmp]",
  standalone: false,
})
export class FolderItemDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({
  selector: "[sub-folder-tmp]",
  standalone: false,
})
export class SubFolderDirective {
  constructor(public template: TemplateRef<any>) {}
}
