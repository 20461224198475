import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";

import { DesignsService } from "@core/api/designs.service";
import { FolderService } from "@core/api/folder.service";
import {
  Creative,
  CreativeFolder,
} from "@core/models/creative-creatives-list.types";
import { AccessService } from "@core/services/access.service";

import {
  FolderContents,
  FolderInfo,
  FoldersComponent,
  ItemDelegate,
  TypeSettings,
} from "@components/folders";

import { CModalComponent } from "@theme/@confect/components/modal/modal.component";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";
import { EmptyStateInfo } from "@theme/components/empty-state/empty-state.component";

import { Subject } from "rxjs";
import { Observable, map } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ngx-designs-overview",
  templateUrl: "./designs-overview.component.html",
  styleUrls: ["./designs-overview.component.scss"],
  standalone: false,
})
export class DesignsOverviewComponent
  implements OnInit, OnDestroy, ItemDelegate<Creative>
{
  folders: CreativeFolder[] = [];
  folderType = "creative";

  COMPANY_CREATIVES = null;
  companyCreativesLoading = false;
  showImportButton = false;
  filterValue = null;

  isLoaded = false;
  simplifiedDisplay = true;

  unsubscribe$ = new Subject();
  selectedDesign;
  _selectedCreativeId;
  settings: TypeSettings = {
    grid: true,
    type: this.folderType,
    display_type: { item: "Design", particle: "a" },
  };

  @ViewChild("companyCreativesModal")
  private companyCreativesModal: CModalComponent;

  @Input() withoutBorders = false;
  @Input() withoutDivide = false;
  @Input() selectionMode = false;
  @Input() duplicateMode = false;
  @Input() radioMode = false;
  @Input() set selectedCreativeId(to) {
    if (to != null) {
      this.designsService.getCreatives(to).subscribe({
        next: (res) => {
          this.selectedDesign = res;
        },
      });
    }
  }
  @Input() mode: "submit" | "radio" = "radio";
  @Input() showFolderOptions = true;

  @Output() selected = new EventEmitter();
  @Output() duplicateAndEdit = new EventEmitter();

  isEmpty = false;
  emptyDetermined = false;
  hasCreatives = false;

  @ViewChild("foldersComponent") folderComponent: FoldersComponent<Creative>;
  @ViewChild("catalogOptions") catalogs: TemplateRef<any>;

  emptyState: EmptyStateInfo = {
    // img: "/assets/images/empty-states/designs.png",
    title: "You have not created any designs yet",
    description: "To start creating content and catalogs, you need a design.",
    buttonText: "Create your first design",
    route: "/designs/new",
    animation: "design-editor-demo",

    secondaryButtonText: "Learn more",
  };

  info = {
    img: "/assets/images/empty-states/designs-instant-updates.png",
    title: "Create a Catalog",
    route: "/automations/feeds",
    description:
      "Change your catalog ads on platforms such as Facebook, by creating a Catalog with updated visuals.",
  };

  showIntro = false;

  extra = {
    moreOptions: [
      {
        action: "dependants",
        display: "Catalogs",
        prio: 1,
        icon: "node_multiple_outlined",
        hide: "noDependants",
      },
    ],
    disableOptions: {
      delete: "This design is currently connected to a catalog.",
    },
  };

  start_folder: number;

  constructor(
    public router: Router,
    protected designsService: DesignsService,
    protected as: AccessService,
    protected folderService: FolderService,
    protected popUpService: CPopupModalService,
  ) {}

  ngOnInit() {
    this.showImportButton = this.as.current.company.accounts.length > 1;
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  trackCreative(index, c) {
    return c.id;
  }

  creativeSelected(creative) {
    this.selected.emit(creative);
  }

  duplicateAndEditSelected(creative) {
    this.duplicateAndEdit.emit(creative);
  }

  switchDisplayMode() {
    this.simplifiedDisplay = !this.simplifiedDisplay;
  }

  importActivated() {
    this.companyCreativesLoading = true;

    this.designsService
      .getCompanyCreatives()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (c) => {
          this.COMPANY_CREATIVES = c;
          this.companyCreativesModal.show();
        },
        complete: () => (this.companyCreativesLoading = false),
      });
  }

  importCreativeSelected(creative) {
    this.designsService
      .importCompanyCreative(creative["id"])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (_res) => {
          this.popUpService.success({
            title: "Imported!",
            autoCloseTimeout: 1500,
          });
          this.folderComponent.reloadFolder();
        },
        error: (_err) => {
          this.popUpService.error({
            title: "Error",
            text: "Could not import design.",
          });
        },
      });
  }

  learnMore() {
    this.router.navigate(["/academy"], {
      state: { path: "/articles/read/your-first-design" },
    });
  }

  loadFolder(
    folder: FolderInfo,
    options?,
  ): Observable<FolderContents<Creative>> {
    const orderBy = options?.order.orderBy;
    const order = options?.order.order;
    return this.designsService
      .getCreativesFolder(folder?.id, orderBy, order)
      .pipe(
        map((res) => {
          if (folder?.id == null) {
            this.isEmpty =
              res.contents.length === 0 && res.folders.length === 0;
            this.showIntro = !(
              res.contents.length > 2 || res.folders.length > 0
            );
            this.emptyDetermined = true;
            this.hasCreatives = res.contents.length > 0;
            this.isLoaded = true;
          }

          return {
            name: folder?.name,
            id: folder?.id,
            items: res.contents,
            folders: res.folders,
          };
        }),
      );
  }

  renameItem(creative): Observable<boolean> {
    return this.designsService.saveCreative(creative).pipe(
      map((res) => {
        return res.success;
      }),
    );
  }

  deleteItem(item: any): Observable<boolean> {
    return this.designsService.deleteCreative(item).pipe(
      map((res) => {
        return res.success;
      }),
    );
  }

  duplicateItem(item: any): Observable<number> {
    return this.designsService.duplicateCreative(item.id).pipe(
      map((res) => {
        return res.id;
      }),
    );
  }

  openItem(item: any) {
    this.router.navigate(["designs", "edit", item.id]);
  }

  getItemImage(item) {
    return item.thumbnail;
  }

  actionsActivated(action: string, item: Creative) {
    switch (action) {
      case "dependants":
        this.popUpService.template({
          template: this.catalogs,
          extra: { item: item },
          showClose: false,
        });
        break;
      case "noDependants":
        return item.automation_dependants == null;
      case "delete":
        return item.automation_dependants != null;
    }
  }
}
