import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { FolderService } from "@core/api/folder.service";
import { MediaService } from "@core/api/media.service";
import { MediaLibraryItem } from "@core/models/media-library.types";

import {
  FolderContents,
  FolderInfo,
  FoldersComponent,
  ItemDelegate,
  TypeSettings,
} from "@components/folders";

import { EmptyStateInfo } from "@theme/components/empty-state/empty-state.component";
import { MediaLibrarySourceService } from "@theme/components/media-library/media-library-source.service";

import { Observable, map, of } from "rxjs";

@Component({
  selector: "ngx-media-overview",
  templateUrl: "./media-overview.component.html",
  standalone: false,
})
export class MediaOverviewComponent
  implements ItemDelegate<MediaLibraryItem>, OnInit, OnDestroy
{
  _radioMode: boolean = false;
  empty_size: "default" | "small" = "default";
  mediaSources;
  loadedFonts = {};

  @Input() set radioMode(to: boolean) {
    if (to == null) {
      return;
    }
    this._radioMode = to;
    if (to) {
      this.empty_size = "small";
    }
  }
  @Input() mode: "submit" | "radio" = "radio";
  @Input() type: "visual" | "font";
  @Input() set folderType(to: "media_visual" | "media_fonts") {
    this.settings = {
      header: true,
      grid: to === "media_fonts" ? false : true,
      noImage: to === "media_fonts",
      listHeader:
        to === "media_fonts"
          ? [
              { name: "name", size: 5 / 10 },
              { name: "example", size: 5 / 10 },
            ]
          : null,
      type: to ?? "",
      display_type: {
        item: to === "media_visual" ? "Media" : "Font",
        particle: "a",
      },
      searchable: true,
    };
    this.source.configure(
      to === "media_visual" ? "uploaded_images" : "uploaded_private_fonts",
      "private",
    );
  }

  @Input() empty_state: EmptyStateInfo = {
    img: "assets/images/empty-states/media-library.png",
    title: "You don't have any media.",
    description:
      "Upload media such as logos and backgrounds to use in your designs",
  };

  @Output() mediaSelect: any = new EventEmitter<any>();

  public source: MediaLibrarySourceService;

  settings: TypeSettings = {
    header: true,
    grid: true,
    type: "",
    display_type: { item: "Media", particle: "a" },
    searchable: true,
  };

  formatType = {
    otf: "opentype",
    woff: "woff",
    woff2: "woff2",
    ttf: "truetype",
  };

  @ViewChild("folders") folders: FoldersComponent<any>;
  constructor(
    protected folderService: FolderService,
    private mediaService: MediaService,
  ) {
    this.source = new MediaLibrarySourceService(mediaService);
  }

  loadFolder(
    folder?: FolderInfo,
    options?: any,
  ): Observable<FolderContents<any>> {
    return this.mediaService.getMediaByFolder(folder?.id, this.type).pipe(
      map((val) => {
        if (this.type === "font") {
          const old = document.getElementById("fonts");
          old != null && old.remove();

          const node = document.createElement("style");
          node.setAttribute("id", "fonts");
          document.body.appendChild(node);
          val.contents.forEach((item) => {
            this.addFont(item, node);
          });
        }
        return {
          name: folder?.name,
          id: folder?.id,
          items: val.contents,
          folders: val.folders,
        };
      }),
    );
  }

  getById(id: number) {
    const currentFolderIdx = this.folders.items.length - 1;

    return this.folders.items[currentFolderIdx].items.find(
      (item) => item.id === id,
    );
  }

  renameItem(item: any): Observable<boolean> {
    return this.mediaService.updateMedia(item.id, {
      source: this.source.key,
      media: { name: item.name },
    });
  }
  deleteItem(item: any): Observable<boolean> {
    return this.mediaService.deactivateMedia(item.id, this.source.key).pipe();
  }
  duplicateItem(item: any): Observable<number> {
    return of(1);
  }
  openItem(item: any) {
    this.mediaSelect.emit(item);
  }
  actionsActivated(action: string, item: any) {
    switch (action) {
      default:
        return true;
    }
  }
  getItemImage(item: any) {}

  addFont(item, node) {
    const fontStr =
      item?.variants?.regular?.url ?? item?.variants?.default?.url;
    const styles = `
    @font-face {
      font-family: 'font${item.id}';
      font-display: swap;
      src: url(${fontStr}) format('${
        this.formatType[fontStr.split(".").pop()]
      }')
    }
    div.font${item.id} {
      font-family: font${item.id} !important
    }
    `;
    node.innerHTML += styles;
    setTimeout(() => {
      this.loadedFonts[item.id] = true;
    });
  }
  ngOnInit(): void {}

  ngOnDestroy(): void {
    const style = document.getElementById("fonts");
    style != null && style.remove();
  }
}
