import { Injectable } from "@angular/core";

import { DesignTemplateSection } from "@core/api-models/designs.models";
import { Feed } from "@core/api-models/feeds.models";
import { StyleGuidelineSpecSection } from "@core/api-models/style-guideline.models";
import { DealOwnerProfile } from "@core/api-models/users.models";
import { Agency } from "@core/models/agency.types";

import { Observable, of, tap } from "rxjs";

import {
  AccountFeatureMeta,
  AdminAgency,
  Company,
  CompanySlim,
  CompanyUser,
  ImageSettings,
  Permissions,
  User,
  UserDetailed,
} from "../api-models/admin.types";
import {
  AdminDesignTemplate,
  AdminDesignTemplateCategory,
  AdminDesignTemplateSpec,
  AdminSharedDesign,
} from "../models/admin-design-templates";
import { NewItem } from "../models/general.types";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AdminService extends ApiService {
  accountFeatures: AccountFeatureMeta[] = [];

  dealOwnerProfiles: DealOwnerProfile[] | null = null;

  // --------------------------
  // Actual admin interface
  adminGetCompanies(): Observable<CompanySlim[]> {
    return this.http.get<CompanySlim[]>(`${this.endpoint}/admin/companies`);
  }

  adminGetCompany(companyID: number): Observable<Company> {
    return this.http.get<Company>(
      `${this.endpoint}/admin/companies/${companyID}`,
    );
  }

  adminCreateCompany(name: string, agency?: number): Observable<NewItem> {
    return this.http.post<NewItem>(`${this.endpoint}/admin/companies`, {
      name: name,
      agency: agency,
    });
  }

  adminUpdateDealOwner(companyID: number, owner: { id: number | null }) {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/admin/companies/${companyID}/deal_owner`,
      owner,
    );
  }

  adminGetCompanyUsers(companyID: number): Observable<CompanyUser[]> {
    return this.http.get<CompanyUser[]>(
      `${this.endpoint}/admin/companies/${companyID}/users`,
    );
  }

  adminCreateCompanyUser(companyID: number, email: string): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/companies/${companyID}/users`,
      { email: email },
    );
  }

  adminDeleteCompanyUser(companyID: number, userID: number): Observable<any> {
    return this.http.delete(
      `${this.endpoint}/admin/companies/${companyID}/users/${userID}`,
    );
  }

  adminResetPasswordCompanyUser(
    companyID: number,
    userID: number,
    newPassword: string,
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/companies/${companyID}/users/${userID}/reset_password`,
      { password: newPassword },
    );
  }

  adminChangeCompanyActive(companyID: number, active: boolean) {
    const verb = active ? "activate" : "deactivate";
    return this.http.post(
      `${this.endpoint}/admin/companies/${companyID}/${verb}`,
      null,
    );
  }

  adminUpdateCompanyPermissions(
    companyID: number,
    newPermissions: Permissions,
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/companies/${companyID}/permissions`,
      newPermissions,
    );
  }

  adminUpdateCompanyDiscount(
    companyID: number,
    discount: number,
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/companies/${companyID}/discount`,
      { discount: discount },
    );
  }

  // adminGetAccount(accountID: number): Observable<Account[]> {
  //   return this.http.get<Account[]>(
  //     `${this.baseURL}/admin/accounts/${accountID}`
  //   );
  // }

  adminGetAccountFeeds(accountID: number): Observable<Feed[]> {
    return this.http.get<Feed[]>(
      `${this.endpoint}/admin/accounts/${accountID}/feeds`,
    );
  }

  adminGetAccountFields(accountID: number): Observable<any> {
    return this.http.get(`${this.endpoint}/admin/accounts/${accountID}/fields`);
  }

  adminUpdateAccountImageSettings(
    accountID: number,
    settings: ImageSettings,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/admin/accounts/${accountID}/image_settings`,
      settings,
    );
  }

  adminUpdateAccountDemoModeFeed(
    accountID: number,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/admin/accounts/${accountID}/demo_feed_refresh`,
      null,
    );
  }

  adminUpdateAccountImagesRequest(
    accountID: number,
  ): Observable<{ product_count: number }> {
    return this.http.post<{ product_count: number }>(
      `${this.endpoint}/admin/accounts/${accountID}/images_refresh_request`,
      null,
    );
  }

  adminUpdateAccountImagesConfirm(
    accountID: number,
    productCount: number,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/admin/accounts/${accountID}/images_refresh_confirm`,
      {
        product_count: productCount,
      },
    );
  }

  adminUpdateAccountPermissions(
    accountID: number,
    newPermissions: Permissions,
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/accounts/${accountID}/permissions`,
      newPermissions,
    );
  }

  adminUpdateAccountDiscount(
    accountID: number,
    discount: number,
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/accounts/${accountID}/discount`,
      { discount: discount },
    );
  }

  adminChangeAccountActive(accountID: number, active: boolean) {
    const verb = active ? "activate" : "deactivate";
    return this.http.post(
      `${this.endpoint}/admin/accounts/${accountID}/${verb}`,
      null,
    );
  }

  adminChangeAccountOnboarding(accountID: number, completed: boolean) {
    return this.http.post(
      `${this.endpoint}/admin/accounts/${accountID}/onboarding`,
      {
        completed: completed,
      },
    );
  }

  adminCreateCompanyAccount(companyID: number, name: string) {
    return this.http.post(
      `${this.endpoint}/admin/companies/${companyID}/accounts`,
      { name: name },
    );
  }

  // adminGetFeed(feedID: number): Observable<Feed> {
  //   return this.http.get<Feed>(`${this.endpoint}/admin/feeds/${feedID}`);
  // }

  // adminGetFeedLookupOptions(feedID: number): Observable<any> {
  //   return this.http.get<any>(`${this.baseURL}/admin/feeds/${feedID}/fields`);
  // }

  adminUpdateFeedApproval(feedID: number, approved: boolean): Observable<any> {
    return this.http.post(`${this.endpoint}/admin/feeds/${feedID}/approval`, {
      approved: approved,
    });
  }

  // --------------------------

  // Users
  adminGetAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.endpoint}/admin/users`);
  }

  adminGetUserDetailed(userID: number): Observable<UserDetailed> {
    return this.http.get<UserDetailed>(
      `${this.endpoint}/admin/users/${userID}`,
    );
  }

  adminUpdateUserPermissions(
    userID: number,
    permissions: Permissions,
  ): Observable<Permissions> {
    return this.http.patch<Permissions>(
      `${this.endpoint}/admin/users/${userID}/permissions`,
      permissions,
    );
  }

  adminRemoveUserAccess(userID: number, companyID: number): Observable<any> {
    return this.http.delete(
      `${this.endpoint}/admin/users/${userID}/access/${companyID}`,
    );
  }

  // Admin agencies
  adminGetAgencies(): Observable<AdminAgency[]> {
    return this.http.get<AdminAgency[]>(`${this.endpoint}/admin/agencies`);
  }

  adminGetAgencyDetails(agencyId: number): Observable<AdminAgency> {
    return this.http.get<AdminAgency>(
      `${this.endpoint}/admin/agencies/${agencyId}`,
    );
  }

  adminCreateAgency(name: string, domain: string): Observable<Agency> {
    return this.http.post<Agency>(`${this.endpoint}/admin/agencies`, {
      name: name,
      domain: domain,
    });
  }

  adminAddAgencyUser(agencyId: number, userId: string): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/agencies/${agencyId}/users/${userId}`,
      {},
    );
  }

  adminRemoveAgencyUser(agencyId: number, userId: number): Observable<any> {
    return this.http.delete(
      `${this.endpoint}/admin/agencies/${agencyId}/users/${userId}`,
    );
  }

  adminAddAgencyCompany(agencyId: number, companyId: number): Observable<any> {
    return this.http.post(
      `${this.endpoint}/admin/agencies/${agencyId}/companies/${companyId}`,
      {},
    );
  }

  adminRemoveAgencyCompany(
    agencyId: number,
    companyId: number,
  ): Observable<any> {
    return this.http.delete(
      `${this.endpoint}/admin/agencies/${agencyId}/companies/${companyId}`,
    );
  }

  // Account Features
  adminGetAvailableAccountFeatures(): Observable<AccountFeatureMeta[]> {
    return this.http.get<AccountFeatureMeta[]>(
      `${this.endpoint}/admin/meta/account_features`,
    );
  }

  // Account Features
  adminGetDealOwnerProfiles(): Observable<DealOwnerProfile[]> {
    if (this.dealOwnerProfiles?.length > 0) {
      return of(this.dealOwnerProfiles);
    }

    return this.http
      .get<
        DealOwnerProfile[]
      >(`${this.endpoint}/admin/meta/deal_owner_profiles`)
      .pipe(tap((profiles) => (this.dealOwnerProfiles = profiles)));
  }

  adminUpdateAccountFeature(
    accountID: number,
    featureKey: string,
    trial_end_date?: string,
  ): Observable<{ success: boolean }> {
    return this.http.patch<{ success: boolean }>(
      `${this.endpoint}/admin/accounts/${accountID}/features/${featureKey}`,
      {
        trial_end_date: trial_end_date,
      },
    );
  }

  adminDeleteAccountFeature(
    accountID: number,
    featureKey: string,
  ): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      `${this.endpoint}/admin/accounts/${accountID}/features/${featureKey}`,
    );
  }

  // Design templates
  getAdminDesignTemplateOptions() {
    return this.http.get<StyleGuidelineSpecSection[]>(
      `${this.endpoint}/admin/templates/options`,
    );
  }

  getAdminDesignTemplates(): Observable<AdminDesignTemplate[]> {
    return this.http.get<AdminDesignTemplate[]>(
      `${this.endpoint}/admin/templates`,
    );
  }

  getAdminDesignTemplate(id: number) {
    return this.http.get<AdminDesignTemplateSpec>(
      `${this.endpoint}/admin/templates/${id}`,
    );
  }
  getAdminDesignTemplateDesigns(
    id: number,
  ): Observable<DesignTemplateSection[]> {
    return this.http.get<DesignTemplateSection[]>(
      `${this.endpoint}/admin/templates/${id}/designs`,
    );
  }
  createAdminDesignTemplate(
    spec: AdminDesignTemplateSpec,
  ): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${this.endpoint}/admin/templates`,
      spec,
    );
  }

  updateAdminDesignTemplate(
    id: number,
    spec: AdminDesignTemplateSpec,
  ): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(
      `${this.endpoint}/admin/templates/${id}`,
      spec,
    );
  }

  getAdminSharedDesigns() {
    return this.http.get<AdminSharedDesign[]>(
      `${this.endpoint}/admin/shared-designs`,
    );
  }

  getAdminSharedDesign(id: number) {
    return this.http.get<AdminSharedDesign>(
      `${this.endpoint}/admin/shared-designs/${id}`,
    );
  }

  createAdminSharedDesign(design: AdminSharedDesign) {
    return this.http.post<{ id: number }>(
      `${this.endpoint}/admin/shared-designs`,
      design,
    );
  }

  updateAdminSharedDesign(id: number, design: AdminSharedDesign) {
    return this.http.post<{ id: number }>(
      `${this.endpoint}/admin/shared-designs/${id}`,
      design,
    );
  }

  deleteAdminSharedDesign(id: number) {
    return this.http.delete<{ success: boolean }>(
      `${this.endpoint}/admin/shared-designs/${id}`,
    );
  }

  getStyleGuidelineSpec() {
    return this.http.get(`${this.endpoint}/style-guidelines/options`);
  }

  getAdminDesignTemplateCategories() {
    return this.http.get<AdminDesignTemplateCategory[]>(
      `${this.endpoint}/admin/templates/categories`,
    );
  }

  adminDesignTemplateCategoryLink(
    templateID: number,
    categoryID: number,
    remove = false,
  ): Observable<{ success: boolean }> {
    if (remove) {
      return this.http.delete<{ success: boolean }>(
        `${this.endpoint}/admin/templates/${templateID}/categories/${categoryID}`,
      );
    }
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/admin/templates/${templateID}/categories`,
      { category_id: categoryID },
    );
  }

  billingAdminUpdateTrial(id: number, trial_end_date: Date) {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/admin/companies/${id}/billing/trial`,
      {
        trial_end_date: trial_end_date,
      },
    );
  }

  billingAdminResetTrial(id: number) {
    return this.http.delete<{ success: boolean }>(
      `${this.endpoint}/admin/companies/${id}/billing/trial`,
    );
  }
}
