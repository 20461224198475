import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { CSlideOverComponent } from "@theme/@confect/components/slide-over/slide-over.component";

@Component({
  selector: "ngx-creative-selector",
  templateUrl: "./creative-selector.component.html",
  styleUrls: ["./creative-selector.component.scss"],
  standalone: false,
})
export class CreativeSelectorComponent implements OnInit {
  @ViewChild("creativeSelector")
  private creativeSelector: CSlideOverComponent;

  @Input() creatives: any[] = [];
  @Output() creativeSelected = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public show() {
    this.creativeSelector.expand();
  }

  public hide() {
    setTimeout(() => {
      this.creativeSelector.hide();
    }, 1);
  }

  public select(c) {
    this.creativeSelected.emit(c);
    this.hide();
  }
}
