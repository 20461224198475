import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "ngx-designs-overview-import",
  templateUrl: "./designs-overview-import.component.html",
  styleUrls: ["./designs-overview-import.component.scss"],
  standalone: false,
})
export class DesignsOverviewImportComponent implements OnInit {
  _creatives = [];

  _keys = [];

  @Input() set creatives(to) {
    this._creatives = to;

    if (to != null) {
      this._keys = Object.keys(to).sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase()),
      );
    }
  }

  @Output() creativeSelected = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  rowClicked(creative) {
    this.creativeSelected.emit(creative);
  }
}
