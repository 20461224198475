<confect-slide-over header="Select design" #creativeSelector>
  <ul class="grid gap-6 grid-cols-2 pb-4">
    @for (c of creatives; track c) {
      <li
        class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-3">
          <div class="w-32 h-32 shrink-0 mx-auto">
            <div
              class="bg-contain bg-transparent bg-no-repeat bg-center w-full h-full"
              [lazyLoad]="c.thumbnail"
              alt=""
            ></div>
          </div>
          <h3
            class="mt-3 text-gray-900 text-sm font-medium text-ellipsis overflow-hidden max-w-full"
          >
            {{ c.name }}
          </h3>
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="flex flex-grow">
              <a
                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-md text-gray-700 font-bold border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer"
                (click)="select(c)"
              >
                Select
              </a>
            </div>
          </div>
        </div>
      </li>
    }
  </ul>
</confect-slide-over>
