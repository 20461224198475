import { Directive, TemplateRef } from "@angular/core";

@Directive({
  selector: "[list-sub-folder-tmp]",
  standalone: false,
})
export class FolderSubListDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({
  selector: "[grid-sub-folder-tmp]",
  standalone: false,
})
export class FolderSubGridDirective {
  constructor(public template: TemplateRef<any>) {}
}
