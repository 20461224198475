import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

import { FolderInfo } from "../folders.component";
import {
  FolderSubGridDirective,
  FolderSubListDirective,
} from "./folders-folder.directive";

@Component({
  selector: "[ngx-folders-folder], ngx-folders-folder",
  templateUrl: "./folders-folder.component.html",
  standalone: false,
})
export class FoldersFolderComponent {
  @Input() folder: FolderInfo;
  @Input() selectMode: boolean;
  @Input() dragged: boolean;
  @Input() selected: boolean;
  @Input() grid: boolean;
  @Input() backFolder: boolean = false;
  @Input() contextOpen: boolean;
  @Input() headerLength: number;
  @Input() noImage: boolean;

  @Output() clicked = new EventEmitter();
  @Output() drag = new EventEmitter();
  @Output() drop = new EventEmitter();
  @Output() rightClicked = new EventEmitter();
  @Output() enter = new EventEmitter();
  @Output() exited = new EventEmitter();

  @ContentChild(FolderSubGridDirective, { read: TemplateRef })
  subFolderGrid: TemplateRef<any>;
  @ContentChild(FolderSubListDirective, { read: TemplateRef })
  subFolderList: TemplateRef<any>;

  open = (folder) => {
    this.clicked.emit(folder);
  };
}
