import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Creative } from "@core/api-models/designs.models";

@Component({
  selector: "ngx-design-select-modal",
  templateUrl: "./design-select-modal.component.html",
  standalone: false,
})
export class DesignSelectModalComponent implements OnInit {
  @Input() selectedCreativeId: number;
  @Input() selectOnClick: boolean = false;
  @Output() submit: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  selectCreative(creative: Creative): void {
    this.selectedCreativeId = creative?.id;
  }

  submitHandler(): void {
    this.submit.emit(this.selectedCreativeId);
  }
}
